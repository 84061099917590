import { IUserProject } from '../../Models';
import { Icon } from '@fluentui/react/lib/components/Icon/Icon';
import { DragHandle } from '../Buttons/DragHandle';

interface IDraggableProjectRowProps {
    project: IUserProject,
    isDragging: boolean
}

const DraggableProjectRow = (props: IDraggableProjectRowProps) => {

    return <>
        <div className="w-11/12 text-base flex flex-row items-center px-3 h-16 cursor-grabbing shadow-md backdrop-blur-md" >
            <div className='inline-flex pr-4'>
                <span className={`hover:shadow-md hidden lg:flex`}><DragHandle /></span>
            </div>
            <div className='truncate w-full'>
                {props.project.favorite && <Icon iconName='FavoriteStar' className='text-primary mr-2' />}
                {props.project.displayTitle ?? props.project.workingPackage.title}
            </div>
        </div>
    </>;
}

export default DraggableProjectRow;