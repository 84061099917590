import { TextField, Icon } from "@fluentui/react";
import { FormEvent, ChangeEvent, useEffect, useState } from "react";
import { Translate, translate } from "react-i18nify";
import { IErrorResponse, IUserProfile, IUserProject, IWorkPackage } from "../../Models";
import { showNotificationWithTimeout, switchShowProjectEditPanel, updateLoadingState, useAppDispatch, useAppSelector, usePutUserProjectMutation } from "../../Redux"
import { IconToggleButton } from "..";
import { DateTime } from "luxon";
import { updateProject } from "../../Redux/projectsSlice";

export const ProjectEditPanel = () => {
    const dispatch = useAppDispatch();
    const dateRange = useAppSelector(s => s.calendar.dateRange);
    const currentProject = useAppSelector(s => s.projects.currentSelectedProject);
    const displayPanel = useAppSelector(s => s.projects.showProjectEditPanel);
    const userProfile = useAppSelector((state) => state.userProfile.userProfile);

    const [putProject, { data: updatedProject, isLoading: isPutLoading, isSuccess: isPutSuccess, isError: isPutError, error: putError }] = usePutUserProjectMutation();

    const [projectTitle, setProjectTitle] = useState<string>('');
    const [projectPt, setProjectPt] = useState<number>(1);
    const [projectStart, setProjectStart] = useState<Date>(dateRange[0]);
    const [projectEnd, setProjectEnd] = useState<Date>(dateRange[1]);
    const [projectIsFavorite, setProjectIsFavorite] = useState<boolean>(false);
    const [projectIsHidden, setProjectIsHidden] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);

    useEffect(() => {
        if (currentProject) {
            setProjectTitle(currentProject.displayTitle ?? currentProject.workingPackage.title);
            setProjectPt(currentProject.workingPackage.totalPT);
            setProjectStart(new Date(currentProject.workingPackage.from))
            setProjectEnd(new Date(currentProject.workingPackage.to))
            setProjectIsFavorite(currentProject.favorite)
            setProjectIsHidden(currentProject.isHidden)
        } else {
            setProjectTitle('');
            setProjectPt(1);
            setProjectStart(dateRange[0])
            setProjectEnd(dateRange[1])
            setProjectIsFavorite(false)
            setProjectIsHidden(false)
        }
    }, [currentProject])

    useEffect(() => {
        setReadOnly(false);
        if (isPutLoading) {
            dispatch(updateLoadingState(true));
        } else {
            if (isPutSuccess) {
                displayNotification('App_Notification_Project_Update_Success');
                dispatch(switchShowProjectEditPanel());
                dispatch(updateProject({ id: currentProject?.id as number, updatedProject }));
            } else if (isPutError && putError) {
                const parsedError: IErrorResponse = { ...putError };
                displayNotification(typeof parsedError.data == 'string' ? parsedError.data : 'Error', true);
            }
            dispatch(updateLoadingState(false));
        }

        dispatch(updateLoadingState(false));
    }, [isPutLoading, isPutSuccess, isPutError, putError]);

    const onTitleChange = (e: ChangeEvent<HTMLTextAreaElement>)  => {
        setProjectTitle(e.target.value);
    }

    const onSaveProjectClick = async () => {
        setReadOnly(true);
        const pt = new Number(projectPt);
        let workPackage: IWorkPackage = {
            title: projectTitle,
            totalPT: pt as number,
            availablePT: pt as number,
            from: projectStart,
            to: projectEnd,
            private: true,
            bookable: true,
        }

        let newProject: IUserProject = {
            displayTitle: projectTitle,
            workingPackage: workPackage,
            favorite: projectIsFavorite,
            isHidden: projectIsHidden,
            userProfile: userProfile as IUserProfile
        }

        if (currentProject && currentProject.id) {
            newProject.id = currentProject.id;
            newProject.workingPackage.id = currentProject.workingPackage.id;

            putProject(newProject);
        }
    }

    const panelDismiss = () => {
        dispatch(switchShowProjectEditPanel());
    }

    const displayNotification = (message: string, isError: boolean = false) => {
        dispatch(showNotificationWithTimeout({
            notificationId: `${Math.random()}`,
            notificationMessage: translate(message),
            isError: isError
        }));
    }

    return (<>
        {
            displayPanel &&
            <div className="relative z-[1011]" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
                <div className="fixed inset-0 bg-neutral bg-opacity-75 transition-opacity"></div>
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 my-auto">
                            <div className="pointer-events-auto relative w-screen max-w-md">
                                <div className="absolute left-full top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                                    <button type="button" className="relative rounded-md text-primary hover:text-primary-dark" onClick={panelDismiss}>
                                        <span className="absolute -inset-2.5"></span>
                                        <span className="sr-only">Close panel</span>
                                        <svg className="h-6 w-6 stroke-primary stroke-1" fill="none" viewBox="0 0 24 24" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>

                                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                        <div className="flex flex-col space-y-10 px-5 justify-center items-center mt-10">
                                            <div className="w-full">
                                                <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900"><Translate value='App_Forms_ProjectTitle' /></label>
                                                <div className="mt-1">
                                                    <textarea rows={2} name="title" id="title" value={projectTitle} onChange={onTitleChange}
                                                        className="block w-full resize-none rounded-sm border-0 p-2 text-black shadow-sm ring-1 ring-inset ring-gray-dark placeholder:text-gray focus:ring-2 focus:ring-inset focus:ring-primary focus:outline-none sm:text-sm sm:leading-6" />
                                                </div>
                                            </div>
                                            <div className="flex flex-row justify-between w-full text-base text-black">
                                                <span><Translate value="App_Forms_StartingFrom" /></span>
                                                <span className="font-semibold">{DateTime.fromJSDate(new Date(projectStart)).toFormat('ccc dd LLL yyyy')}</span>
                                            </div>
                                            <div className="flex flex-row justify-between w-full text-base text-black">
                                                <span><Translate value="App_Forms_EndDate" /></span>
                                                <span className="font-semibold">{DateTime.fromJSDate(new Date(projectEnd)).toFormat('ccc dd LLL yyyy')}</span>
                                            </div>
                                            <div className="flex flex-row justify-between w-full text-base text-black">
                                                <span><Translate value="App_Grid_TotalPT" /></span>
                                                <span className="font-semibold">{projectPt}</span>
                                            </div>
                                            <IconToggleButton checked={projectIsFavorite}
                                                label="App_Forms_Favorite"
                                                iconName={projectIsFavorite ? 'FavoriteStarFill' : 'FavoriteStar'}
                                                onClick={() => setProjectIsFavorite(!projectIsFavorite)} />
                                            <IconToggleButton checked={projectIsHidden}
                                                label="App_Forms_Hide_Project"
                                                iconName={projectIsHidden ? 'Hide' : 'RedEye'}
                                                onClick={() => setProjectIsHidden(!projectIsHidden)} />

                                            <div className="flex flex-row">
                                                <button type="button" className="rounded-sm bg-primary px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-primary/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary" onClick={onSaveProjectClick}>
                                                    <Translate value="App_Button_Save" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>)
}