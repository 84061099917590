import { Icon } from '@fluentui/react/lib/components/Icon/Icon';
import { Translate } from 'react-i18nify';

interface IConfirmDialogProps {
    isHidden: boolean,
    infoTitle: string,
    infoText: string,
    icon?: string,
    onConfirmClick: Function,
    onDismissClick: Function,
}

export const ConfirmDialog = (props: IConfirmDialogProps) => {
    const { isHidden, infoTitle, infoText, icon, onConfirmClick, onDismissClick } = { ...props }

    return (<>
        {
            !isHidden &&
            <div className="relative z-50" aria-labelledby="delete-confirm-modal" role="dialog" aria-modal="true">
                <div className="fixed inset-0 bg-neutral bg-opacity-75 transition-opacity"></div>
                <div className="fixed inset-0 z-[1011] overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all mb-9 lg:mb-0 sm:my-8 sm:w-full sm:max-w-lg">
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        {icon && <Icon iconName={icon} />}
                                    </div>
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <h3 className="text-base font-semibold leading-6 text-clack" id="delete-confirm-modal"><Translate value={infoTitle} /></h3>
                                        <div className="mt-2">
                                            <p className="text-sm text-black"><Translate value={infoText} /></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button type="button" className="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-tertiary shadow-sm hover:bg-primary/70 sm:ml-3 sm:w-auto" onClick={() => onConfirmClick()}><Translate value='App_Button_Yes' /></button>
                                <button type="button" className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-black shadow-sm ring-1 ring-inset ring-primary/50 hover:bg-gray/50 sm:mt-0 sm:w-auto" onClick={() => onDismissClick()}><Translate value='App_Button_No' /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </>
    )
}