import { Target } from '@fluentui/react';
import { ContextualMenuItemType, DirectionalHint, IContextualMenuProps, IContextualMenuItem, ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { useCallback } from 'react';
import { translate } from 'react-i18nify';

interface IProjectGroupContextualMenu {
    targetRef: HTMLElement | null
    isHidden: boolean,
    onRenameClick: Function,
    onDeleteClick: Function,
    onDismissClick: Function
}

export const ProjectGroupContextualMenu = (props: IProjectGroupContextualMenu) => {
    const menuItems: IContextualMenuItem[] = [
        { key: 'rename', text: translate('App_Button_Rename'), iconProps: { iconName: 'Edit' }, onClick: () => props.onRenameClick() },
        { key: 'divider_1', itemType: ContextualMenuItemType.Divider },
        { key: 'delete', text: translate('App_Button_Delete'), iconProps: { iconName: 'Trash' }, onClick: () => props.onDeleteClick() },
    ]

    const onHideContextualMenu = useCallback(() => props.onDismissClick(), []);

    return (<>
        <ContextualMenu
            isBeakVisible
            target={props.targetRef}
            items={menuItems}
            hidden={props.isHidden}
            directionalHint={DirectionalHint.rightCenter}
            onDismiss={onHideContextualMenu}
            />
    </>)
}