export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_API_CLIENT_ID,
    authority: "https://login.microsoftonline.com/" + process.env.REACT_APP_TENTANT_ID, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: process.env.REACT_APP_REDIRECT_URL
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
 scopes: [process.env.REACT_APP_API_SCOPE] 
};


// ToDo: Check why production app reg is different
//  scopes: ["https://metafinanz.onmicrosoft.com/timetable/user_impersonation"]