import { Translate } from "react-i18nify"

export const UnexpectedError = () => {
    return (<>
        <div className="w-full h-full flex flex-col text-center space-y-5">
            <div className="w-full text-[200px] text-primary font-bold">500</div>
            <div className="w-full text-6xl">Unexpected Error</div>
            <div className="w-full text-xl"><Translate value='App_Info_Please' /> <a href="mailto:myIT@metafinanz.de" className="text-primary cursor-pointer"><Translate value='App_Info_Contact_Admin' /></a></div>
        </div>
    </>)
}