import { Icon } from "@fluentui/react/lib/components/Icon"
import { Translate } from "react-i18nify"

interface IAddItemButton {
    onClick: Function
}

export const AddItemButton = (props: IAddItemButton) => {
    return (<>
        <div className="lg:flex lg:text-sm items-center space-x-2 text-primary cursor-pointer my-2" onClick={() => props.onClick()}>
            <Icon iconName='Add' className='text-primary font-light text-lg'></Icon>
            <span><Translate value='App_Button_Add' /></span>
        </div>
    </>)
}