import { ComboBox, IComboBox, IComboBoxOption, Label } from "@fluentui/react";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useId } from '@fluentui/react-hooks';
import { IUserProfile } from "../../Models";
import { setPublicHolidays, setUserProfile, updateLoadingState, useAppDispatch, useAppSelector, useFetchCountriesQuery, useLazyFetchPublicHolidaysQuery, useUpdateCountryMutation } from "../../Redux";
import { InfoBubble } from "../Info/InfoBubble";
import { Divider } from "..";

export const CountryPicker = () => {
    const dispatch = useAppDispatch();
    const dateRange = useAppSelector(s => s.calendar.dateRange);
    const userProfile = useAppSelector(s => s.userProfile.userProfile);
    const isNavigationPanelOpen = useAppSelector((state) => state.userProfile.isNavigationPanelOpen);
    const dropdownId = useId('ddnCountryPicker');
    const targetContainer = useRef<HTMLDivElement>(null);

    const [countryOptions, setCountryOptions] = useState<IComboBoxOption[]>([]);
    const [selectedKey, setSelectedKey] = useState<number | undefined>();
    const [showBubble, setShowBubble] = useState<boolean>(false);

    const { data: countryResult, isSuccess } = useFetchCountriesQuery();
    const [getPublicHolidays, { data: publicHolidaysResult, isSuccess: isRetrieveSuccess }] = useLazyFetchPublicHolidaysQuery();
    const [updateCountry, { isSuccess: isUpdateSuccess, isLoading }] = useUpdateCountryMutation();

    useEffect(() => {
        setTimeout(() => {
            if (isSuccess && countryResult) {
                setCountryOptions(countryResult.map(item => ({ key: item.id, text: `${item.countryName}${item.county ? ' - ' : ''}${item.county ?? ''}` })));
            }
        }, 1000)
    }, [isSuccess, countryResult])

    useEffect(() => {
        if (isLoading) {
            dispatch(updateLoadingState(true));
        }

        if (isUpdateSuccess) {
            dispatch(updateLoadingState(false));
            getPublicHolidays({ countryId: selectedKey as number, from: dateRange[0], until: dateRange[1] });
        }
    }, [isUpdateSuccess, isLoading])

    useEffect(() => {
        if (isRetrieveSuccess && publicHolidaysResult) {
            dispatch(setPublicHolidays(publicHolidaysResult));
        }
    }, [isRetrieveSuccess, publicHolidaysResult])

    useEffect(() => {
        if (userProfile && userProfile.country_Id) {
            setSelectedKey(userProfile.country_Id);
            setShowBubble(false);
        } else {
            setShowBubble(true);
        }
    }, [userProfile?.country_Id]);

    const onCountryChange = (event: FormEvent<IComboBox>, option?: IComboBoxOption | undefined, index?: number | undefined) => {
        if (option) {
            setSelectedKey(option?.key as number);
            let updatedUserProfile = { ...userProfile };
            updatedUserProfile.country_Id = option.key as number;
            dispatch(setUserProfile(updatedUserProfile as IUserProfile));

            updateCountry(option.key)
            setShowBubble(false);
        }
        else {
            setSelectedKey(undefined);
        }
    }

    return (<>
        <div ref={targetContainer} className="w-full">
            <Divider text='App_Forms_Country_Picker' translate />
            <div className={`absolute top-37 z-[1010] flex flex-col items-end
                            ${isNavigationPanelOpen ? 'left-73' : 'left-26'}`}>
                <InfoBubble showBubble={showBubble}
                    title='App_Info_Country_Selection_Title'
                    text='App_Info_Country_Selection'
                    onCloseCallback={() => setShowBubble(false)} />
            </div>
            <ComboBox
                id={dropdownId}
                selectedKey={selectedKey}
                onChange={onCountryChange}
                allowFreeform
                dropdownWidth={200}
                options={countryOptions}
                iconButtonProps={{ iconProps: { iconName: "MapPin" } }} />
        </div>
    </>)
}
