import { CountdownTimer } from "./CountdownTimerComponent";
import { setMaintenanceEndDate, useAppDispatch, useGetAdministrationQuery } from "../../Redux";
import { useEffect } from "react";
import { addDateTimeOffset } from "../../Utils";

export const Maintenance = () => {
    const dispatch = useAppDispatch();
    const { data: administrationData, isSuccess } = useGetAdministrationQuery();

    useEffect(() => {
        if (isSuccess && administrationData && administrationData.endDateMaintenance) {
            let dateWithOffset = addDateTimeOffset(new Date(administrationData.endDateMaintenance));
            dispatch(setMaintenanceEndDate(dateWithOffset));
        }
    }, [administrationData]);

    return (<>
        {
            administrationData && <CountdownTimer />
        }
    </>)
}