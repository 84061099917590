import { Icon } from "@fluentui/react/lib/components/Icon"
import { Translate } from "react-i18nify"
import { useAppDispatch, useAppSelector } from "../../Redux";
import { switchOverwriteHiddenProperty } from "../../Redux/projectsSlice";

export const ShowHiddenProjectsButton = () => {
    const dispatch = useAppDispatch();
    const overwriteHiddenProperty = useAppSelector(s => s.projects.overwriteHiddenProperty);

    const onClick = () => {
        dispatch(switchOverwriteHiddenProperty());
    }

    return (<>
        <div className="flex lg:w-full h-12 text-sm justify-start items-center space-x-2 text-primary cursor-pointer" onClick={onClick}>
            <Icon iconName={!overwriteHiddenProperty ? 'RedEye' : 'Hide'} className='text-primary font-light text-lg'></Icon>
            <span><Translate value={!overwriteHiddenProperty ? 'App_Button_Show_Hidden_Projects' : 'App_Button_Hide_Hidden_Projects'} /></span>
        </div>
    </>)
}