import { useMsal } from "@azure/msal-react";
import { Translate } from "react-i18nify";
import { ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline';

export const SignOutButton = () => {
    const { instance, accounts } = useMsal();

    const onSignOutClick = () => {
        instance.logoutRedirect({
            account: accounts[0],
            postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URL
        });
    }

    return (<>
        <div className="group flex lg:w-full h-12 text-sm justify-start items-center space-x-2 text-primary cursor-pointer hover:font-semibold" onClick={onSignOutClick}>            
            <ArrowLeftOnRectangleIcon className="stroke-primary h-4 w-4 stroke-[1] group-hover:stroke-[1.5]" />
            <span><Translate value='App_SignOut' /></span>
        </div>
    </>);
}