import { IActivityTemplate } from "../../Models";
import { transformMinutesToBookedTime } from "../../Utils";
import { DragHandle } from "../Buttons/DragHandle";

interface IDraggedActivityTemplateRow{
    bookingComment: IActivityTemplate
}

export const DraggedActivityTemplateRow = (props: IDraggedActivityTemplateRow) => {
    return (
        <>
            <div className="w-full flex flex-row items-center space-x-1 h-16 px-2 shadow-md backdrop-blur-md" >
                <div className="w-1/6 truncate">
                    <span className='px-2'><DragHandle /></span>
                    {props.bookingComment.title}
                </div>
                <div className="w-4/6 truncate">
                    {props.bookingComment.description}
                </div>
                <div className="hidden lg:block w-1/12 truncate select-none">
                    {transformMinutesToBookedTime(props.bookingComment.defaultBookedTime)}
                </div>
            </div>
        </>
    )
}