import { TextField, MessageBarType, Icon } from "@fluentui/react";
import { FormEvent, useEffect, useState } from "react";
import { translate } from "react-i18nify";
import { IErrorResponse, IUserProfile, IProjectGroup } from "../../Models";
import { showNotificationWithTimeout, updateLoadingState, useAppDispatch, useAppSelector, usePostProjectGroupMutation, usePutProjectGroupMutation } from "../../Redux"

interface IProjectGroupEditItem {
    projectGroup?: IProjectGroup,
    callback: Function
}

export const ProjectGroupEditItem = (props: IProjectGroupEditItem) => {
    const dispatch = useAppDispatch();
    const projectGroups = useAppSelector(s => s.projectGroups.projectGroupsList);
    const userProfile = useAppSelector((state) => state.userProfile.userProfile);

    const [postProjectGroup, { isLoading: isPostLoading, isSuccess: isPostSuccess, isError: isPostError, error: postError }] = usePostProjectGroupMutation();
    const [putProjectGroup, { isLoading: isPutLoading, isSuccess: isPutSuccess, isError: isPutError, error: putError }] = usePutProjectGroupMutation();

    const [groupTitle, setGroupTitle] = useState<string>(props.projectGroup ? props.projectGroup.title : '');
    const [readOnly, setReadOnly] = useState<boolean>(false);

    useEffect(() => {
        if (isPostLoading || isPutLoading) {
            dispatch(updateLoadingState(true));
            setReadOnly(true);
        }
    }, [isPostLoading, isPutLoading]);

    useEffect(() => {
        setReadOnly(false);
        if (isPostSuccess) {
            displayNotification('App_Notification_Project_Group_Create_Success');
            setGroupTitle('');
            props.callback();
        } else if (isPostError && postError) {
            const parsedError: IErrorResponse = { ...postError };
            displayNotification(typeof parsedError.data == 'string' ? parsedError.data : 'Error', true);
        }

        dispatch(updateLoadingState(false));
    }, [isPostSuccess, isPostError, postError]);

    useEffect(() => {
        setReadOnly(false);
        if (isPutSuccess) {
            displayNotification('App_Notification_Project_Group_Update_Success');
            props.callback();
        } else if (isPutError && putError) {
            const parsedError: IErrorResponse = { ...putError };
            displayNotification(typeof parsedError.data == 'string' ? parsedError.data : 'Error', true);
        }

        dispatch(updateLoadingState(false));
    }, [isPutSuccess, isPutError, putError]);

    const onTitleChange = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setGroupTitle(newValue ?? groupTitle);
    }

    const onSaveProjectClick = async () => {
        if (props.projectGroup) {
            let tempGroup: IProjectGroup = { ...props.projectGroup }
            tempGroup.title = groupTitle;
            tempGroup.userProfile = userProfile as IUserProfile;
            tempGroup.userProfile_Id = userProfile?.id as number;
            tempGroup.userProjects = [];

            putProjectGroup(tempGroup)
        }
        else {
            let newGroup: IProjectGroup = {
                title: groupTitle,
                order: projectGroups != null ? projectGroups.length + 1 : 1,
                projectCount: 0,
                userProfile: userProfile as IUserProfile,
                userProfile_Id: userProfile?.id as number,
                userProjects: []
            }

            postProjectGroup(newGroup);
        }
    }

    const displayNotification = (message: string, isError: boolean = false) => {
        dispatch(showNotificationWithTimeout({
            notificationId: `${Math.random()}`,
            notificationMessage: translate(message),
            isError: isError 
        }));
    }

    return (<>
        <div className="flex w-full border-gray-dark border-[1px] rounded-md shadow-md bg-white mt-1">
            <div className="flex flex-col w-full space-y-1 px-1">
                <div className="w-full flex items-center justify-center">
                    <TextField value={groupTitle} placeholder={translate('App_Forms_ProjectGroupTitle')} underlined className="w-full" onChange={onTitleChange} disabled={readOnly} />
                </div>
                <div className='flex items-center justify-around'>
                    <div>
                        <Icon iconName='Save' onClick={onSaveProjectClick} className="text-sm cursor-pointer text-primary" />
                    </div>
                    <div>
                        <Icon iconName='ChromeClose' onClick={() => props.callback()} className="text-sm cursor-pointer" />
                    </div>
                </div>
            </div>
        </div>
    </>)
}