import { useDroppable } from "@dnd-kit/core";
import { Icon } from "@fluentui/react"
import { Translate } from "react-i18nify";

export const UngroupProjectContainer = () => {

    const { setNodeRef: removeNodeRef, isOver } = useDroppable({ id: 'ungroup' });

    return (<div ref={removeNodeRef} className={`border-dashed border-2 rounded-md w-full h-24 font-extrabold mt-4
                                                text-xl text-center text-primary-dark flex items-center justify-center 
                                                ${isOver ? 'text-2xl shadow-2xl border-primary' : 'shadow-none border-primary-dark'}`}  >
        <Icon iconName="UngroupObject" className='px-3' /> <Translate value="App_Label_Drop_To_Remove" />
    </div>)
}