import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { IActivityTemplate } from '../Models/IActivityTEmplate';

interface IAddActivityTemplateModalProps {
    isHidden: boolean,
    editableActivityTemplate?: IActivityTemplate,
    confirmCallback?: Function
}

interface IActivityTemplatesStateState {
    activityTemplatesList: IActivityTemplate[],
    addActivityTemplateModalProps: IAddActivityTemplateModalProps
}

const initialState: IActivityTemplatesStateState = {
    activityTemplatesList: [],
    addActivityTemplateModalProps: { isHidden: true }
}

export const activityTemplatesSlice = createSlice({
    name: 'activityTemplates',
    initialState,
    reducers: {
        setActivityTemplates: (state, action: PayloadAction<IActivityTemplate[] | undefined>) => {
            if (action.payload) {
                state.activityTemplatesList = action.payload;
            }
        },
        showAddActivityTemplateDialog: (state, action: PayloadAction<{confirm: Function, activityTemplate?: IActivityTemplate}>) => {
            state.addActivityTemplateModalProps.isHidden = false;
            state.addActivityTemplateModalProps.confirmCallback = action.payload.confirm;            
            state.addActivityTemplateModalProps.editableActivityTemplate = action.payload.activityTemplate ?? undefined;            
        },
        hideAddActivityTemplateDialog: (state, action: PayloadAction<void>) => {
            state.addActivityTemplateModalProps.isHidden = true;
            state.addActivityTemplateModalProps.confirmCallback = undefined;
            state.addActivityTemplateModalProps.editableActivityTemplate = undefined;
        }
    }
});

export const activityTemplatesApi = createApi({
    reducerPath: 'activityTemplates_api',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/api/skills`,
        prepareHeaders(headers) {
            headers.set("Authorization", "Bearer " + localStorage.getItem("accessToken"));
            headers.set("userIdentifier", localStorage.getItem("userIdentifier") ?? "");

            return headers;
        }
    }),
    tagTypes: ['activityTemplates'],
    endpoints(builder) {
        return {
            fetchActivityTemplates: builder.query<IActivityTemplate[], void>({
                query() {
                    return '/GetSkills';
                },
                providesTags: ['activityTemplates']
            }),
            postActivityTemplate: builder.mutation({
                query: activityTemplate => ({
                    url: '/PostSkill',
                    method: 'POST',
                    body: activityTemplate
                }),
                invalidatesTags: ['activityTemplates']
            }),
            putActivityTemplate: builder.mutation({
                query: activityTemplate => ({
                    url: `/PutSkill?id=${activityTemplate.id}`,
                    method: 'PUT',
                    body: activityTemplate
                }),
                invalidatesTags: ['activityTemplates']
            }),
            updateActivityTemplateOrder: builder.mutation({
                query: newactivityTemplateOrder => ({
                    url: `/UpdateSkillsOrder`,
                    method: 'POST',
                    body: newactivityTemplateOrder
                }),
                invalidatesTags: ['activityTemplates']
            }),
            deleteActivityTemplate: builder.mutation({
                query: activityTemplateId => ({
                    url: `/DeleteSkill?id=${activityTemplateId}`,
                    method: 'DELETE'
                }),
                invalidatesTags: ['activityTemplates']
            })
        }
    }
});

export const { useFetchActivityTemplatesQuery,
    useLazyFetchActivityTemplatesQuery,
    usePostActivityTemplateMutation,
    usePutActivityTemplateMutation,
    useUpdateActivityTemplateOrderMutation,
    useDeleteActivityTemplateMutation } = activityTemplatesApi;

export const { setActivityTemplates, showAddActivityTemplateDialog, hideAddActivityTemplateDialog } = activityTemplatesSlice.actions;

export default activityTemplatesSlice.reducer;