import { useEffect, useState } from 'react';
import { getDateRangeArray, Calendar, DayOfWeek, ICalendarDayProps } from '@fluentui/react';
import { useAppDispatch, useAppSelector, updateDateRange, useLazyFetchPublicHolidaysQuery, setPublicHolidays } from '../../Redux';

export const DatePicker = () => {
    const dispatch = useAppDispatch();
    const userProfile = useAppSelector(s => s.userProfile.userProfile);
    const dateRange = useAppSelector(s => s.calendar.dateRange);
    const currentSelectedDate = useAppSelector(s => s.calendar.selectedDate);
    const dateRangeType = useAppSelector(s => s.calendar.dateRangeType);
    const calendarStrings = useAppSelector((state) => state.calendar.calendarStrings);
    const publicHolidays = useAppSelector((state) => state.publicHoliday.publicHolidays);

    const [getPublicHolidays, { data: publicHolidaysResult, isSuccess: isRetrieveSuccess }] = useLazyFetchPublicHolidaysQuery();

    const [selectedDate, setSelectedDate] = useState<Date>(dateRange[0]);

    const calendarDayProps: Partial<ICalendarDayProps> = {
        customDayCellRef: (element, date, classNames) => {
            if (element) {
                var holidays = publicHolidays.filter((v) => { return new Date(v.date).toDateString() == date.toDateString() })
                if (holidays.length > 0) {
                    element.classList.add('dayMarker');
                    element.title = holidays[0].localName;
                } else {
                    if (element.classList.contains('dayMarker')) {
                        element.classList.remove('dayMarker');
                        element.title = '';
                    }
                }
            }
        },
    };

    const onSelectDate = (date: Date, dateRangeArray?: Date[]): void => {
        setSelectedDate(date);
        if (dateRangeArray != null) {
            dispatch(updateDateRange(dateRangeArray));
        }
    }

    useEffect(() => {
        var initDateRange = getDateRangeArray(currentSelectedDate, dateRangeType, DayOfWeek.Monday);

        setSelectedDate(initDateRange[0]);
        dispatch(updateDateRange(initDateRange));
    }, [dateRangeType]);

    useEffect(() => {
        if (dateRange && dateRange.length > 0) {
            let firstDate = dateRange[0];
            let now = new Date();

            setSelectedDate(firstDate.getMonth() == now.getMonth() ? now : dateRange[0]);
            if (userProfile?.country_Id && userProfile?.country_Id > 0) {
                getPublicHolidays({ countryId: userProfile?.country_Id as number, from: dateRange[0], until: dateRange[1] });
            }
        }
    }, [dateRange]);


    useEffect(() => {
        if (isRetrieveSuccess && publicHolidaysResult) {
            dispatch(setPublicHolidays(publicHolidaysResult));
        }
    }, [isRetrieveSuccess, publicHolidaysResult])

    useEffect(() => {
        if (userProfile && userProfile.country_Id && userProfile.country_Id > 0 && dateRange && dateRange.length > 0) {
            getPublicHolidays({ countryId: userProfile.country_Id as number, from: dateRange[0], until: dateRange[1] });
        }
    }, [userProfile]);

    return (<>
        <Calendar
            showMonthPickerAsOverlay
            showGoToToday
            dateRangeType={dateRangeType}
            onSelectDate={onSelectDate}
            value={selectedDate}
            firstDayOfWeek={DayOfWeek.Monday}
            strings={calendarStrings}
            calendarDayProps={calendarDayProps}
        />
    </>);
}