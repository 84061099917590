import { Icon } from "@fluentui/react/lib/components/Icon/Icon"
import { DateTime } from "luxon";
import { useAppDispatch, updateDateRange, useAppSelector } from "../../Redux";
import { DateRangeType } from "@fluentui/date-time-utilities/lib/dateValues/dateValues";

interface IMonthNavigationArrow {
    past?: boolean
}

export const MonthNavigationArrow = (props: IMonthNavigationArrow) => {

    const dispatch = useAppDispatch();
    const dateRange = useAppSelector(s => s.calendar.dateRange);
    const dateRangeType = useAppSelector(s => s.calendar.dateRangeType);

    const onArrowClick = () => {
        const firstDate = DateTime.fromJSDate(dateRange[0]);
        let newStart: DateTime = firstDate;
        let newEnd: DateTime = DateTime.fromJSDate(dateRange[1]);

        if (props.past) {
            if (dateRangeType == DateRangeType.Month) {
                newStart = firstDate.minus({ months: 1 });
                newEnd = newStart.plus({ months: 1 }).minus({ days: 1 });
            }
            else {
                newStart = firstDate.minus({ days: 7 });
                newEnd = firstDate.minus({ days: 1 });
            }
        }
        else {
            if (dateRangeType == DateRangeType.Month) {
                newStart = firstDate.plus({ months: 1 });
                newEnd = newStart.plus({ months: 1 }).minus({ days: 1 });
            } else {
                newStart = firstDate.plus({ days: 7 });
                newEnd = newEnd.plus({ days: 7 });
            }
        }

        dispatch(updateDateRange([newStart.toJSDate(), newEnd.toJSDate()]));
    }

    return (<>
        <Icon iconName={props.past ? "ChevronLeft" : "ChevronRight"} onClick={onArrowClick} className="text-lg cursor-pointer text-primary" />
    </>)
}