import { useEffect, useState } from 'react';
import { Translate } from 'react-i18nify';
import { IUserProject } from '../../Models';
import TimeEntryContainer from '../TimeEntry/TimeEntryContainerComponent';
import { Icon } from '@fluentui/react/lib/components/Icon/Icon';
import { useAppDispatch, useAppSelector, useFetchTotalBookedOnProjectQuery, openProjectEditPanel } from '../../Redux';
import { DateRangeType } from '@fluentui/date-time-utilities/lib/dateValues/dateValues';
import { useDraggable } from '@dnd-kit/core';
import { DragHandle } from '../Buttons/DragHandle';
import { PencilIcon } from '@heroicons/react/24/outline';
import { ProjectBillableType } from './ProjectBillableTypeComponent';
import { updateProject } from '../../Redux/projectsSlice';
import { Divider } from '../Divider/DividerComponent';
import { DateTime } from 'luxon';

interface ProjectRowProps {
    project: IUserProject,
    isExpanded: boolean
}

export const ProjectRow = (props: ProjectRowProps) => {
    var dispatch = useAppDispatch();
    const dateRange = useAppSelector((state) => state.calendar.dateRange);
    const dateRangeType = useAppSelector(s => s.calendar.dateRangeType);
    const overwriteHiddenProperty = useAppSelector(s => s.projects.overwriteHiddenProperty);
    const projectGroups = useAppSelector((state) => state.projectGroups.projectGroupsList);

    const [project, setProject] = useState<IUserProject>(props.project);
    const [showBookings, setShowBookings] = useState<boolean>(props.isExpanded);
    const [isFavorite, setIsFavorite] = useState<boolean>(props.project.favorite);
    const [invoiceBookingClosureDate, setInvoiceBookingClosureDate] = useState<string>('');
    const [showBookingClosureInfo, setShowBookingClosureInfo] = useState<boolean>(false);
    const { data: totalBookedHours } = useFetchTotalBookedOnProjectQuery({ dateRange, workPackageId: props.project.workingPackage.id as number }, { skip: dateRange.length === 0 });

    const { attributes, listeners, setNodeRef, isDragging } = useDraggable({ id: props.project.id as number });

    useEffect(() => {
        setIsFavorite(project.favorite);
    }, [project]);

    useEffect(() => {
        setShowBookings(props.isExpanded);
        if (props.project.workingPackage.xrmInvoiceBookingClosure) {
            let closureDate = new Date(props.project.workingPackage.xrmInvoiceBookingClosure);
            setShowBookingClosureInfo(closureDate >= dateRange[1] || (closureDate < dateRange[1] && closureDate > dateRange[0]));
            setInvoiceBookingClosureDate(DateTime.fromJSDate(closureDate).toFormat("dd LLL yyyy"))
        }
    }, [dateRange]);

    const onRowClick = () => {
        setShowBookings(!showBookings);
    }

    const onEditProjectClick = () => {
        dispatch(openProjectEditPanel(project));
    }

    const updateAvailablePT = (minutes: number, subtractHours: boolean) => {
        var pt = (subtractHours ? -1 : 1) * (minutes / 60 / 8);
        var workpackage = { ...project.workingPackage, availablePT: project.workingPackage.availablePT + pt };
        setProject({ ...project, workingPackage: workpackage });
        dispatch(updateProject({ id: project.id as number, updatedProject: { ...project, workingPackage: workpackage } }));
    }

    return (<>
        {((!overwriteHiddenProperty && !project.isHidden) || overwriteHiddenProperty) &&
            <div className={`bg-white rounded-md lg:ml-0 ${showBookings && 'pt-5 px-3 shadow-md'}`}>
                {showBookings && showBookingClosureInfo && <Divider isWhite translatedText={<Translate value='App_Info_Invoice_Booking_Closure_On_Project' date={invoiceBookingClosureDate} />} />}
                <div className={`w-full flex flex-row flex-wrap xl:flex-nowrap items-center px-3 relative bg-white
                           border-l-4 rounded-md border-primary text-primary hover:z-[12]
                            ${showBookings ? 'bg-gradient-to-r from-secondary via-lightning to-coral h-[86px] lg:h-20 rounded-b-none' : 'h-[86px] lg:h-16'} 
                            ${isDragging ? 'blur' : 'blur-none'}`} ref={setNodeRef}>
                    {!showBookings && projectGroups && projectGroups.length > 0 &&
                        <div className='cursor-grab inline-flex pr-4'>
                            <span {...attributes} {...listeners} aria-label="Move Project To Group Handle" className="hover:shadow-md hidden lg:flex cursor-grab"><DragHandle /></span>
                        </div>
                    }
                    <div className={`cursor-pointer w-[90%] xl:w-[80%] ${showBookings && 'pl-7 w-[95%]'}`} onClick={onRowClick}>
                        <div className='truncate w-full font-semibold'>
                            {isFavorite && <Icon iconName='FavoriteStar' className='text-primary mr-2' />}
                            {project.displayTitle ?? project.workingPackage.title}
                        </div>
                        <div className='text-primary text-xs flex flex-row space-x-2 slashed-zero'>
                            <span>
                                <Translate value='App_Grid_TotalPT' />: {project.workingPackage.totalPT} - <Translate value='App_Grid_AvailablePT' /> {project.workingPackage.availablePT}
                            </span>
                            <span>
                                {
                                    dateRangeType == DateRangeType.Month ?
                                        <span><Translate value='App_Forms_TotalHoursBookedMonth' hours={`${totalBookedHours ?? 0}`} /></span>
                                        : <span><Translate value='App_Forms_TotalHoursBookedWeek' hours={`${totalBookedHours ?? 0}`} /></span>
                                }
                            </span>
                        </div>
                    </div>
                    <div className='hidden xl:flex w-[16%]'>
                        {project.workingPackage.xrmBillingType && <ProjectBillableType typeText={project.workingPackage.xrmBillingType} />}
                    </div>
                    <div className={`w-[3%] mr-2 flex items-center justify-end text-md text-center text-primary`}>
                        <PencilIcon className='cursor-pointer stroke-2 stroke-primary h-4 w-5' onClick={onEditProjectClick} />
                    </div>
                    {
                        project.workingPackage.xrmBillingType &&
                        <div className={`flex xl:hidden w-full flex-row text-xs pl-4 ${showBookings && 'pl-7'}`}>
                            <ProjectBillableType typeText={project.workingPackage.xrmBillingType} />
                        </div>
                    }
                </div>
                {((!project.isHidden || overwriteHiddenProperty) && showBookings) && <TimeEntryContainer workPackage={project.workingPackage} updateWorkpackageCallback={updateAvailablePT} />}
            </div>
        }
    </>);
}