import { DateRangeType } from "@fluentui/date-time-utilities/lib/dateValues/dateValues";
import { Translate } from "react-i18nify";
import { useAppSelector, useFetchTotalBookedForDateRangeQuery } from "../../Redux";

export const TotalBookedHours = () => {
    const dateRange = useAppSelector(s => s.calendar.dateRange);
    const dateRangeType = useAppSelector(s => s.calendar.dateRangeType);

    const { data: totalHours } = useFetchTotalBookedForDateRangeQuery({ dateRange }, { skip: dateRange.length === 0 });

    return (<div className="text-xs flex items-center justify-center text-primary my-2 font-semibold">
        {
            dateRangeType === DateRangeType.Week ?
            <Translate value='App_Label_Sum_Week' hours={`${totalHours}`} />
            : <Translate value='App_Label_Sum_Month' hours={`${totalHours}`} />
        }
    </div>)
}