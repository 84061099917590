import { Translate } from "react-i18nify";
import { describeArc } from '../../Utils';

interface ICountdownCircle {
    value: number,
    radius: number,
    label: string
}

export const CountdownCircle = (props: ICountdownCircle) => {
    return(
        <>
            {props.value > 0 && (
                <div className='text-primary text-4xl flex items-center justify-center flex-col leading-7 m-3 pt-3 relative w-24 h-24'>
                    <svg className='absolute top-0 left-0 w-25 h-25'>
                        <path fill="none" stroke="#373737" strokeWidth="4" d={describeArc(50, 50, 48, 0, props.radius)}/>
                    </svg>
                    {props.value}
                    <span className='text-black text-xs font-semibold uppercase'><Translate value={props.label} /></span>
                </div>
            )}
        </>
    );
}