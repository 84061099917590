import { Icon } from "@fluentui/react"
import { ReactElement } from "react";
import { Translate } from "react-i18nify";

interface IDividerProps {
    left?: boolean,
    right?: boolean,
    iconLeftName?: string,
    iconRightName?: string,
    translatedText?: ReactElement,
    text?: string,
    translate?: boolean,
    isWhite?: boolean,
    clickable?: boolean,
    onClick?: Function
}

export const Divider = (props: IDividerProps) => {
    const { left, right, iconLeftName, iconRightName, translatedText, text, translate, isWhite, clickable, onClick } = { ...props }

    const handleClick = () => {
        if (clickable && onClick) {
            onClick();
        }
    }

    return (
        <div className="bg-transparent transparent py-2 w-full">
            <div className="px-4 max-w-full mx-auto">
                <div className="relative">
                    <div className="flex items-center inset-0 absolute" aria-hidden="true">
                        <div className="border-t-[1px] w-full border-primary">
                        </div>
                    </div>
                    <div className={`flex relative ${left ? 'justify-start' : right ? 'justify-end' : 'justify-center'}`} onClick={handleClick}>
                        <span className={`text-primary text-opacity-70 text-sm px-2 ${isWhite ? 'bg-white' : 'bg-neutral'} select-none ${clickable ? 'cursor-pointer' : 'cursor-default'}`}>
                            {iconLeftName && <Icon iconName={iconLeftName} />} {translatedText ?? ""}{translate ? <Translate value={text as string} /> : text} {iconRightName && <Icon iconName={iconRightName} />}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}