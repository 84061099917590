import { Translate } from "react-i18nify";
import { DatePicker, DayOfWeek, Icon } from '@fluentui/react';
import { useState } from 'react';
import { useAppSelector, usePutStartAndEndMaintenanceDateMutation } from "../../Redux";
import { onFormatDate } from "../../Utils";
import { NumberInput } from "../Inputs/NumberInput";
import { IAdministration } from "../../Models/IAdministration";

export const SetMaintainenceDatesComponent = () => {
    const calendarStrings = useAppSelector((state) => state.calendar.calendarStrings);

    const [startDate, setStartDate] = useState<Date | null | undefined>(new Date());
    const [endDate, setEndDate] = useState<Date | null | undefined>(new Date());

    const [maintenanceTimeStart, setMaintenanceTimeStart] = useState<number>(0);
    const [maintenanceTimeEnd, setMaintenanceTimeEnd] = useState<number>(0);

    const [putStartAndEndMaintenanceDate, { }] = usePutStartAndEndMaintenanceDateMutation();

    const onTimeStartChange = (value: number) => {
        setMaintenanceTimeStart(value);
    }

    const onTimeEndChange = (value: number) => {
        setMaintenanceTimeEnd(value);
    }

    const onEditMaintenanceDates = async () => {
        let start: Date = startDate as Date;
        start.setHours(maintenanceTimeStart, 0, 0, 0);

        let end: Date = endDate as Date;
        end.setHours(maintenanceTimeEnd, 0, 0, 0);

        putStartAndEndMaintenanceDate({ startDate: start, endDate: end });
    }

    return (
        <div className="relative flex flex-col space-y-3 w-full p-3 border border-primary rounded-sm shadow-md">
            <div className="absolute -top-3 left-2 inline-block bg-white px-1 text-base font-medium text-primary">
                <span className='drop-shadow-md'>Maintainence settings</span>
            </div>
            <div className="flex flex-row space-x-2">
                <div>
                    <label className="text-sm"><Translate value="App_Forms_Maintenance_StartDate" /></label>
                </div>
                <DatePicker
                    className='w-full flex-1'
                    showMonthPickerAsOverlay
                    showGoToToday
                    formatDate={onFormatDate}
                    onSelectDate={setStartDate as (date: Date | null | undefined) => void}
                    value={startDate as Date}
                    firstDayOfWeek={DayOfWeek.Monday}
                    strings={calendarStrings} />
                <div>
                    <label className="text-sm"><Translate value="App_Forms_Maintenance_Time" /></label>
                </div>
                <NumberInput min={0} max={23} step={1} onChange={onTimeStartChange} value={maintenanceTimeStart} />
            </div>
            <div className="flex flex-row space-x-2">
                <div>
                    <label className="text-sm"><Translate value="App_Forms_Maintenance_EndDate" /></label>
                </div>
                <DatePicker
                    className='w-full flex-1'
                    showMonthPickerAsOverlay
                    showGoToToday
                    formatDate={onFormatDate}
                    onSelectDate={setEndDate as (date: Date | null | undefined) => void}
                    value={endDate as Date}
                    firstDayOfWeek={DayOfWeek.Monday}
                    strings={calendarStrings} />
                <div>
                    <label className="text-sm"><Translate value="App_Forms_Maintenance_Time" /></label>
                </div>
                <NumberInput min={0} max={23} step={1} onChange={onTimeEndChange} value={maintenanceTimeEnd} />
            </div>
            <div className="flex flex-row justify-center">
                <button className="flex flex-row items-center justify-center space-x-2 text-sm text-primary cursor-pointer" onClick={onEditMaintenanceDates}>
                    <Icon iconName="Save" />
                    <span><Translate value='App_Button_Save' /></span>
                </button>
            </div>
        </div>)
}