import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DateTime } from 'luxon';
import { IPublicHoliday } from '../Models';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


interface IPublicHolidayState {
    publicHolidays: IPublicHoliday[]
}

const initialState: IPublicHolidayState = {
    publicHolidays: []
}

export const publicHolidaySlice = createSlice({
    name: 'publicHoliday',
    initialState,
    reducers: {
        setPublicHolidays: (state, action: PayloadAction<IPublicHoliday[]>) => {
            state.publicHolidays = action.payload;
        }
    }
});

export const publicHolidayApi = createApi({
    reducerPath: 'publicholiday_api',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_URL}/api/publicholiday`,
        prepareHeaders(headers) {
            headers.set("Authorization", "Bearer " + localStorage.getItem("accessToken"));
            headers.set("userIdentifier", localStorage.getItem("userIdentifier") ?? "");

            return headers;
        }
    }),
    endpoints(builder) {
        return {
            fetchPublicHolidays: builder.query<IPublicHoliday[], { countryId: number, from: Date, until: Date }>({
                query(params) { 
                    return `/GetPublicHolidaysByCountryIdAndDateRange?countryId=${params.countryId}&from=${params.from.toDateString()}&until=${params.until.toDateString()}`; 
                },
            })
        }
    }
});

export const { setPublicHolidays } = publicHolidaySlice.actions;

export const { useFetchPublicHolidaysQuery, useLazyFetchPublicHolidaysQuery } = publicHolidayApi;

export default publicHolidaySlice.reducer;