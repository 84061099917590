import { IUserProject } from '../../Models';
import { ProjectRow } from '..';

interface IProjectsListProps {
    projects: IUserProject[]
}

export const ProjectsList = (props: IProjectsListProps) => {
    return (<div className='mx-2 lg:ml-8 lg:mr-4 space-y-3 z-[1]'>
        {
            props.projects && props.projects.length > 0 &&
            props.projects.map((p, i) => <ProjectRow key={p.id} project={p} isExpanded={props.projects.filter(p => !p.isHidden).length == 1 && !p.isHidden} />)
        }
    </div>)
}