import { Translate } from "react-i18nify";
import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import { switchShowUserSettings, useAppDispatch } from "../../Redux";

export const UserSettingsButton = () => {
    const dispatch = useAppDispatch();
    
    const onSettingsClick = () => {
        dispatch(switchShowUserSettings());
    }

    return (<>
        <div className="group flex lg:w-full h-12 text-sm justify-start items-center space-x-2 text-primary cursor-pointer hover:font-semibold" onClick={onSettingsClick}>
            <Cog6ToothIcon className='text-primary font-light h-4 w-4 stroke-[1] group-hover:stroke-[1.5]'></Cog6ToothIcon>
            <span><Translate value='App_Button_Settings' /></span>
        </div>
    </>);
}