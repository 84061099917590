import { Icon } from "@fluentui/react/lib/components/Icon"
import { useEffect, useState } from "react"
import { Translate } from "react-i18nify"

interface IIconToggleButton {
    checked: boolean,
    label?: string,
    iconName: string,
    disabled?: boolean,
    onClick?: Function
}

export const IconToggleButton = (props: IIconToggleButton) => {
    const { checked, label, iconName, disabled, onClick } = { ...props }

    const [active, setActive] = useState<boolean>(checked);

    useEffect(() => {
        setActive(checked);
    }, [checked])

    const handleClick = () => {
        if (!disabled) {
            setActive(!active);
            onClick && onClick();
        }
    }

    return (<div className="w-full flex flex-row items-center space-x-3">
        {
            label && <span>
                <Translate value={label} />
            </span>
        }
        <span className="relative flex justify-center items-center hover:cursor-pointer" onClick={handleClick}>
            <Icon iconName={iconName}
                className={`relative select-none text-xl
                            ${active ? 'drop-shadow-[0_0_8px_rgba(133,75,255,0.5)] text-primary-alternate' : 'drop-shadow-none text-primary'}
                            ${disabled ?? 'text-gray-dark'}`} />
        </span>
    </div>)
}